<template>
  <v-card min-width="50%" style="border-shadow: none">
    <div v-if="!success && !error">

    <v-card-title primary-title class="justify-center">
      Shipping Information
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form v-model="validForm">
        <v-row>
          <v-text-field
              v-model="formData.burnTxHash"
              outlined
              label="Token Burn Transaction Hash"
              class=""
              required
              :disabled="disableInput"
              :rules="rules.txHash"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
              v-model="formData.fullName"
              outlined
              label="Full Name"
              class="mr-1"
              :rules="rules.required"
              required
          ></v-text-field>
          <v-text-field
              v-model="formData.email"
              outlined
              label="Email"
              class="ml-1"
              required
              :rules="rules.email"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
              v-model="formData.address"
              outlined
              label="Full Shipping Address"
              class=""
              required
              :rules="rules.required"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
              v-model="formData.additionalInformation"
              outlined
              label="Additional information"
              class=""
          ></v-text-field>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center" style="display: grid">
      <v-row justify="center">
        <vue-recaptcha :sitekey="recaptchaSiteKey" :loadRecaptchaScript="true" @verify="handleCaptcha"></vue-recaptcha>
      </v-row>

      <v-row justify="center" class="mt-10">
        <v-btn v-if="showSignBtn" color="black" class="white--text" :disabled="!validForm || !recaptchaResponse" :loading="loading" large
               @click="submitInformation"> Submit
        </v-btn>
      </v-row>
    </v-card-actions>
          </div>
          <div v-else-if="success">
           <v-card-text class="pt-3">
          <v-row no-gutters justify="center" class="py-4">
            <v-icon size="100" color="success">mdi-check-circle</v-icon>
          </v-row>
          <v-row no-gutters justify="center" class="pt-4 text-body-1">
            You registered your claim successfully.
          </v-row>
                    <v-row no-gutters justify="center" class="text-body-1">
            We will contact you after the shipping
          </v-row>
        </v-card-text>
          </div>
        <div v-else-if="error">
                   <v-card-text class="pt-3">
          <v-row no-gutters justify="center" class="py-4">
            <v-icon size="100" color="error">mdi-alert</v-icon>
          </v-row>
          <v-row no-gutters justify="center" class="py-4 text-body-1">
            Error during form submission
          </v-row>
          <v-row
            no-gutters
            justify="center"
            class="mt-n3 red--text"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </v-row>

        </v-card-text>
          </div>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {ethers} from 'ethers'
import {mapFields} from 'vuex-map-fields'
import VueRecaptcha from 'vue-recaptcha'
import {recaptchaSiteKey} from "@/utils/constants"

export default {
  components: {
    VueRecaptcha,
  },
  props: {
    disableInput: {
      type: Boolean,
      default: true,
    },
    showSignBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recaptchaSiteKey,
      loading: false,
      error: false,
      errorMessage: "",
      success: false,
      rules: {
        txHash: [
        
        v => !!v || 'Transaction hash is required',
          v => /^0x([A-Fa-f0-9]{64})$/.test(v) || "Invalid Transaction Format",
        ],
        email: [
          v => !!v || 'Email is required',
          v => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v) || "Invalid Email",
        ],
        required: [
          v => !!v || 'Required field',
        ],
      },
    }
  },
  computed: {
    ...mapGetters("connectweb3", ["getUserSigner"]),
    ...mapFields("prizeContract", ["formData", "validForm", "recaptchaResponse"]),
  },
  methods: {
    ...mapActions("prizeContract", ["submitFormToLambda"]),
    handleCaptcha(res) {
      this.recaptchaResponse = res
    },
    submitInformation() {
      this.loading = true
      let payload = `Burn Transaction Hash: ${this.formData.burnTxHash}, Full Name: ${this.formData.fullName}, Email: ${this.formData.email}, Full Address: ${this.formData.address}, Additional Information: ${this.formData.additionalInformation}`;
      console.log(payload)
      this.getUserSigner.signMessage(payload).then((signature) => {
        console.log(signature)
        let signAddress = ethers.utils.verifyMessage(payload, signature)
        console.log(signAddress)
        this.submitFormToLambda({payload, signature, signAddress, recaptcha: this.recaptchaResponse, formData: this.formData}).then((res) => {
            console.log("res", res)
            this.loading = false
            if(res.data.status == 200 && res.data.code == "success"){
              this.success = true;
            }else{
              let message = res.data.message ? res.data.message : "";
              throw new Error(message)
            }
            
        })
        //TODO: svuotare form ... feedback successo!
      }).catch((err) => {
        this.loading = false
        this.error = true
        this.errorMessage = err
      })
    },
  },
}
</script>

<style>
</style>